import Container from 'react-bootstrap/Container';
import { useRef } from 'react';
import { MemoryRouter, Routes, Route} from 'react-router-dom';
import io from 'socket.io-client';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home';
import Answer_Round from './pages/answer_round';
import Waiting_Room from './pages/waiting_room';
import Vote_Round from './pages/vote_round';
import Score_Round from './pages/score_round';
import Fight_Round from './pages/fight_round';
import { v4 as uuidv4 } from 'uuid';
import Movie_Round from './pages/movie_round';
import Char_Select from './pages/char_select';
import { useEffect } from "react";

const uid = uuidv4();

//const socket = io.connect('http://localhost:4000',
const socket = io.connect('https://server.hotreels.party/',
{
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
  autoConnect: true,
  withCredentials: true,     // Increase timeout slightly to account for slower heartbeat
  extraHeaders: {
    uid: uid //setting UID here so it's known on first connection. setting on server is hard to track on first connection
   },
  
}
);


function App() {
  const movie = useRef({});
  const answers = useRef([]);
  const username = useRef('');
  const roomcode = useRef('');
  //const [scoreboard, setScoreboard] = useState({});

  useEffect(() => {
    const handleConnect = () => {
        console.log("Connected to server, socket ID:", socket.id);
    };

    const handleReconnect = (attempt) => {
        console.log(`Reconnected after ${attempt} attempts!`);
        socket.emit("reconnect_requested");
    };

    const handleReconnectAttempt = (attempt) => {
        console.log(`Attempting to reconnect... Attempt #${attempt}`);
    };

    // Ensure previous listeners are removed before adding new ones
    socket.off("connect", handleConnect);
    socket.on("connect", handleConnect);

    socket.off("reconnect", handleReconnect);
    socket.on("reconnect", handleReconnect);

    socket.off("reconnect_attempt", handleReconnectAttempt);
    socket.on("reconnect_attempt", handleReconnectAttempt);

    return () => {
        socket.off("connect", handleConnect);
        socket.off("reconnect", handleReconnect);
        socket.off("reconnect_attempt", handleReconnectAttempt);
    };
}, []);

  return (
    <Container fluid className="App">
      <MemoryRouter>
          <Header />
          <Routes>
            <Route path='/' element={<Home 
              username={username} 
              roomcode={roomcode} 
              socket={socket}
              movie={movie}
              answers={answers}
            //  setScoreboard = {setScoreboard}
              />} />

          <Route path='/waiting_room'element={<Waiting_Room 
          username={username}
          roomcode = {roomcode}
          movie= {movie}
          socket={socket} />}/>

          <Route path='/movie_round'element={<Movie_Round 
          username={username}
          roomcode = {roomcode}
          movie={movie}
          socket={socket} />}/>

          <Route path='/answer_round'element={<Answer_Round 
            username={username}
            roomcode = {roomcode}
            movie = {movie}
            answers = {answers}
            socket={socket} />}/>


          <Route path='/vote_round'element={<Vote_Round 
            username={username}
            roomcode = {roomcode}
            movie = {movie}
            answers = {answers}
           // setScoreboard = {setScoreboard}
            socket={socket}
            />}/>

          <Route path='/score_round'element={<Score_Round 
          //  scoreboard = {scoreboard}
            roomcode = {roomcode}
            movie = {movie}
            socket={socket}
            answers = {answers} />}/> 


          <Route path='/fight_round'element={<Fight_Round 
              //  scoreboard = {scoreboard}
                roomcode = {roomcode}
                answers = {answers}
                movie = {movie}
                socket={socket}
               />}/> 

          <Route path='/char_select'element={<Char_Select
              //  scoreboard = {scoreboard}
                roomcode = {roomcode}
                socket={socket}
               />}/> 


          </Routes>
          {/* <Footer /> */}
        </MemoryRouter>
  </Container>
  );
}

export default App;
