const femaleParts = [
    "cheeks",
    "blossom",
    "flaps",
    "beaver",
    "clam",
    "slit",
    "nockers",
    "milkers",
    "cakes",
    "rim",
    "bazookas",
    "poon",
    "hole",
    "fissure",
    "cooter",
    "gash",
    "pouch",
    "sheath",
    "tunnel",
    "natties",
    "cookie",
    "lips",
    "snatch",
    "cherry",
    "oyster",
    "mounds",
    "hills",
    "peaks",
    "orbs",
    "globes",
    "melons",
    "peach",
    "buns",
    "jugs",
    "pillows",
    "cushions",
    "bumps",
    "box",
    "bits",
    "curves",
    "bosoms",
    "chest",
    "bust",
    "nips",
    "lumps",
    "jigglers",
    "kitty",
    "honkers",
    "pair",
    "twins"
  ];

  const maleParts = [
    "wang",
    "weiner",
    "sausage",
    "carrot",
    "wood",
    "bum",
    "nards",
    "log",
    "hog",
    "spire",
    "bulge",
    "shaft",
    "rod",
    "pole",
    "stick",
    "prick",
    "wand",
    "staff",
    "spear",
    "lance",
    "dagger",
    "sword",
    "dong",
    "nuts",
    "turret",
    "hammer",
    "pipe",
    "hose",
    "pecker",
    "cox",
    "balls",
    "tip",
    "trunk",
    "johnson",
    "twig",
    "stalk",
    "sack",
    "fist",
    "pickle",
    "salami",
    "pike",
    "hotdog",
    "wanks",
    "snake",
    "chubby",
    "thing",
    "spunk",
    "nub",
    "willy",
    "tusk",
  ];

  const partDescriptors = [
    "Slick",
    "Stacked",
    "Drippy",
    "Stanky",
    "Pierced",
    "Puffy",
    "Veiny",
    "Funky",
    "Dirty",
    "Hefty",
    "Swollen",
    "Juicy",
    "Rigid",
    "Soppy",
    "Plump",
    "Oozing",
    "Fleshy",
    "Spicy",
    "Slimy",
    "Chubby",
    "Fat",
    "Thick",
    "Sticky",
    "Beefy",
    "Perky",
    "Loose",
    "Creamy",
    "Hairy",
    "Damp",
    "Busty",
    "Glazed",
    "Curvy",
    "Saucy",
    "Huge",
    "Sloppy",
    "Ripe",
    "Sweaty",
    "Rank",
    "Mushy",
    "Deep",
    "Steamy",
    "Round",
    "Tasty",
    "Meaty",
    "Hot",
    "Wild",
    "Firm",
    "Gooey",
    "Raw",
    "Hard"
];

const pornStarMiddleNames = [
  "Randy",
  "Nasty",
  "Frisky",
  "Spicy",
  "Kinky",
  "Flirty",
  "Sassy",
  "Rowdy",
  "Foxy",
  "Freaky",
  "Thirsty",
  "Horny",
  "Tramp",
  "Feral",
  "Hussy",
  "Savage",
  "Tart",
  "Skanky",
  "Sleazy",
  "Sus"

];
  
  // Function to generate a username
  const generateUsername = (username, gender) => {
    // Pick a random descriptor
    const descriptor = partDescriptors[
      Math.floor(Math.random() * partDescriptors.length)
    ];
    
    // Pick a random middle name
    const middleName = pornStarMiddleNames[
      Math.floor(Math.random() * pornStarMiddleNames.length)
    ];
    
    // Pick a gender-appropriate part
    const partsList = gender === 'male' ? maleParts : femaleParts;
    const part = partsList[Math.floor(Math.random() * partsList.length)];
    
    // Combine to form username with spaces between parts, middle name in quotes
    return ` ${middleName} ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()} ${descriptor}${part}`;
  };

  export { maleParts, femaleParts, partDescriptors, pornStarMiddleNames, generateUsername };