import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom'; 
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';

const Waiting_Room = ({username, movie, roomcode,socket}) => {
    const [user, setUser] = useState([]);
    const navigate = useNavigate();
    const [hasStarted, setHasStarted] = useState(false);
    const [blockScroll, allowScroll] = useScrollBlock();
    
    // Block scroll on mount and clean up on unmount
    useEffect(() => {
        blockScroll();
        return () => allowScroll();
    }, []);
    
    // useEffect(() => {
    //     //user joined waitroom
    //     socket.on('add_to_waitroom', (username) => {
    //         setUser([...username]);
    //     });
    //     return () => socket.off('add_to_waitroom');
    //     }, [socket]);

    
    
    useEffect(() => {
        //user joined waitroom
        socket.emit('joined_wait_room', { username:username.current, roomcode:roomcode.current }
        )},
        []);

    useEffect(() => {
        //someone clicked start game
        socket.on('game_started', (data) => {
        setHasStarted(true);
        navigate('/char_select', { replace: true });
       // navigate('/movie_round', { replace: true });

        });
        return () => socket.off('game_started');
        }, [socket]);


    // useEffect(() => {
    //     //intro done
    //     socket.on('answer_round_started', (randMovie) => {
    //         movie.current = randMovie;
    //         console.log(movie.current.title);
    //         navigate('/answer_round', { replace: true });
    //     });
    //     return () => socket.off('answer_round_started');
    //     }, [socket]);
    
    
    //game start button click actions
    const onClick = (ev) => {
        ev.preventDefault();
        socket.emit('start_game', { username:username.current, roomcode:roomcode.current });
        console.log(`button pressed`);
        setHasStarted(true);
    }




    return (
//NEED TO CHANGE IT SO IT ADDS USER TO A STACK OF USERS
    <Body>
            
                
                
            

            {/* <Stack direction="horizontal"  gap={3}>
            {user.map(users => (
            <div className="p-2">{users}</div>
            ))}
            </Stack><br></br> */}


            {!hasStarted &&
                    <div>
                        <h1> When all players are here, press start game</h1>
                        <br></br>
                        <Button onClick={onClick} variant="primary" type="submit">Start Game</Button>
                    </div>
            }
            {hasStarted &&
        
                    <TvAttention/>
            }

    </Body>

    );
};

export default Waiting_Room;