import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function GenderSelectButtons({ onGenderSelect, selectedGender }) {
  return (
    <div className="gender-select-container">
      <p>Choose your crown:</p>
      <ButtonGroup>
        <Button 
          variant={selectedGender === 'male' ? 'primary' : 'outline-primary'} 
          onClick={() => onGenderSelect('male')}
        >
          King
        </Button>
        <Button 
          variant={selectedGender === 'female' ? 'primary' : 'outline-primary'} 
          onClick={() => onGenderSelect('female')}
        >
          Queen
        </Button>
        <Button 
          variant={selectedGender === 'surprise' ? 'primary' : 'outline-primary'} 
          onClick={() => onGenderSelect('surprise')}
        >
          Surprise Me
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default GenderSelectButtons;