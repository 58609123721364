import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Body from '../../components/Body';
import InputField from '../../components/InputField';
import GenderSelectButtons from '../../components/GenderSelectButtons';
import { useNavigate } from 'react-router-dom';
import useScrollBlock from '../../components/useScrollBlock';
import Cookies from 'js-cookie';
import { generateUsername } from '../../components/PornWordLists';

const Home = ({username,roomcode,socket,movie, answers}) => {
const [formErrors, setFormErrors] = useState({});
const [userName, setuserName] = useState("");
const [selectedGender, setSelectedGender] = useState("");
const usernameField = useRef();
const roomcodeField = useRef();
const navigate = useNavigate();
const [reconnect, setReconnect] = useState(false);
const [blockScroll, allowScroll] = useScrollBlock();


blockScroll();

useEffect(() => {
  usernameField.current.focus();
}, []);

useEffect(() => {
  //db updated
  socket.on('player_added_to_db', () => {
  navigate('/waiting_room', { replace: true });
  }); 
  return () => socket.off('player_added_to_db');
  }, [socket]);


  // useEffect(() => {
  //   //db updated
  //   socket.on('connect', () => {
  //     console.log("connected");
  //     const testCookie = Cookies.get('io');
  //     console.log('io cookie ' + testCookie);
  //   }); 
  //   return () => socket.off('connect');
  //   }, [socket]);

    useEffect(() => {
     
      //add reconnect button to screen
      socket.on('reconnectOption', () => {
        setReconnect(true);
      }); 
      return () => socket.off('reconnectOption');
      }, [socket]);

      //navigate to games current round page
    useEffect(() => {
      socket.on('reconnect_round', (data) => {
        roomcode.current = data.room;
        switch (data.round){
          case 'wait':
            navigate('/waiting_room', { replace: true });
            break;
          case 'answer':
            movie.current = data.data;
            console.log('movie is ' + movie.current);
            navigate('/answer_round', { replace: true });
            break;
          case 'vote':
            //answers.current = data.data; need a way to prevent self-voting
            navigate('/vote_round', { replace: true });
            break;
          case 'score':
            navigate('/score_round', { replace: true });
            break;
        }

      }); 
      return () => socket.off('reconnect_round');
      }, [socket]);




//Submit button click
const onSubmit = (ev) => {
  ev.preventDefault();
  const basicUsername = usernameField.current.value;
  roomcode.current = roomcodeField.current.value.toUpperCase();
  
  const errors = {};
  if (!basicUsername) {
    errors.username = 'Username must not be empty.';
  }
  if (!roomcode) {
    errors.roomcode = 'Roomcode must not be empty.';
  }
  if (!selectedGender) {
    errors.gender = 'Please select a gender.';
  }
  setFormErrors(errors);
  if (Object.keys(errors).length > 0) {
    return;
  }
  else 
  {
    // Determine the actual gender to use
    let actualGender = selectedGender;
    
    // If "surprise me" is selected, randomly choose male or female
    if (selectedGender === 'surprise') {
      actualGender = Math.random() < 0.5 ? 'male' : 'female';
      console.log(`Surprise option selected! Random gender chosen: ${actualGender}`);
    }
    
    // Generate the enhanced username with the word lists using the actual gender
    const enhancedUsername = generateUsername(basicUsername, actualGender);
    username.current = enhancedUsername;
    
    console.log(`Original: ${basicUsername}, Enhanced: ${enhancedUsername}, Gender: ${actualGender}`);
    
    socket.emit('join_room', {
      username: enhancedUsername, 
      roomcode: roomcode.current
    });
    //write another emission for when the database is updated before navigating
    console.log(`Join Room Emitted`);
  }
};


//limit character count and enforce one word username
const handleChange = (e) => {
  // Remove any spaces from username (ensuring one word only)
  if (usernameField.current && usernameField.current.value) {
    usernameField.current.value = usernameField.current.value.replace(/\s+/g, '');
  }

  // Limit character length
  if (usernameField.current.value.length >= 10) {
    usernameField.current.value = usernameField.current.value.slice(0,10);
  }
  if (roomcodeField.current.value.length >= 4) {
    roomcodeField.current.value = roomcodeField.current.value.slice(0,4);
  }
}

//reconnect button press
const onClick = (ev) => {
  ev.preventDefault();
  socket.emit('reconnect_requested',);
}

  // Handle gender selection
  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
  };

  return (

  <Body>
        <Form onSubmit={onSubmit} onChange={handleChange}>
          <div className="mb-3">
            <GenderSelectButtons 
              onGenderSelect={handleGenderSelect} 
              selectedGender={selectedGender} 
            />
            {formErrors.gender && <div className="text-danger">{formErrors.gender}</div>}
          </div>
          <InputField name="username" label="A first name:" error={formErrors.username} fieldRef={usernameField}   />
          <InputField name="roomcode" label="Roomcode:" error={formErrors.roomcode} fieldRef={roomcodeField}  />
          <Button variant="primary" type="submit">Submit</Button>
          <br/><br/>
          <p className="tos"><small>By clicking Submit, you agree to our <a href ="https://www.gethotreels.com/tos/" target="_blank" rel="noreferrer">Terms of Use</a></small></p>
          <p className="ppolicy"><small> <a href ="https://www.gethotreels.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a></small></p>
        </Form>
        <br/><br/>

        {reconnect &&
         <Button onClick={onClick} variant="primary" type="submit">Reconnect</Button>
        }


  </Body>

  );
};

export default Home;